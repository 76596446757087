@media screen and ( min-width: 2200px ) {
    .project-flex {
        font-size: 0.7vw;
        line-height: 1.2vw !important; }

    .menu ul {
        font-size: 1.1vw;
        line-height: 1.7vw; }

    .logo {
        width: 4vw; }

    .feature-title {
        font-size: 1.1vw;
        line-height: 1.7vw; }

    .content-about, .about-titles {
        font-size: 1vw; }

    .whatwedo {
        line-height: 1.6vw; }

    .team-description {
        font-size: 0.85vw;
        line-height: 1.35vw; }

    .team-position {
        font-size: 0.8vw; }

    h1 {
        font-size: 1.7vw; }

    .pledge-body {
        font-size: 1vw;
        line-height: 1.5vw; }

    .projects {
        margin-left: 1.2vw;
        max-width: 12vw; }

    .feature-info {
        font-size: 0.75vw;
        line-height: 1.1vw;
        margin-left: 12vw; } }
