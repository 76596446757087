.content-forward {
    margin-left: 1.5vw; }

.contact-title {
    margin-bottom: 2vw; }

.phone {
    margin-bottom: 4vw; }

.extra-info {
    margin-bottom: 4vw; }

.address p {
    margin: 0.5vh 0; }

.extra-info p {
    margin: 0.5vh 0; }

#map {
    margin-top: 4.5vw;
    width: 72vw;
    height: 40vw; }

.mapboxgl-ctrl {
    display: none !important; }

.social-icons {
    margin-top: 3vh; }

@media only screen and (min-width: 835px) {
    #map {
        margin-top: 1.8vw;
        width: 35vw;
        height: 20vw; }

    .phone {
        margin-bottom: 1.8vw; }

    .extra-info {
        margin-bottom: 1.8vw; }

    .social-icons {
        margin-top: 2vw;
        font-size: 1vw; } }
