.article-date {
    text-transform: uppercase; }

.article-title {
    margin-left: 1.5vw; }

.article-title:hover {
    color: $pink; }

.content-news {
    width: 85vw;
    margin-top: 4vh;
    margin-left: 3vw;
    margin-bottom: 10vh; }

@media only screen and (min-width: 835px) {
    .content-news {
        width: 85vw;
        margin-bottom: 3vw; } }
