
$background : #F2F3EB;
$pink : #E06D93;

@font-face {
    font-family: "HKGrotesk";
    src: url("../fonts/HKGrotesk-Regular.otf") format("opentype"); }

html, body {
    overflow: hidden; }

html {
    height: -webkit-fill-available; }

body {
    font-family: "HKGrotesk", sans-serif;
    background-color: $background;
    letter-spacing: 0.06em;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available; }

h4 {
    font-weight: normal; }

a {
    text-decoration: none;
    color: black;
    -webkit-tap-highlight-color: transparent; }

a:hover {
    text-decoration: none;
    color: $pink; }

u {
    text-decoration-thickness: 0.05vw;
    text-underline-offset: 0.15vw; }

* {
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent; }
