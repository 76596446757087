.content-pledge {
    width: 85vw;
    margin-top: 4vh;
    margin-left: 3vw;
    margin-bottom: 10vh; }

.signature-flex {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 2.5vw; }

.signature-img {
    width: 35vw;
    height: 5vh; }

.signature-description {
    font-size: 3vw;
    margin: 0.3vh;
    text-transform: uppercase; }

.signature-position {
    font-size: 2.8vw;
    text-transform: none; }

@media only screen and (max-width: 835px) {
    .signature-div {
        margin-top: 3vw; } }

@media only screen and (min-width: 835px) {
    .content-pledge {
        width: 45vw;
        margin-bottom: 4vw; }

    .signature-flex {
        flex-wrap: nowrap;
        justify-content: space-between; }

    .pledge-body {
        font-size: 1.3vw;
        line-height: 1.9vw; }

    .signature-img {
        width: 8vw;
        height: 8vh; }

    .signature-description {
        font-size: 0.8vw;
        margin: 0.3vh; }

    .signature-position {
        font-size: 0.75vw; } }
