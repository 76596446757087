
.content-about {
    margin-left: 3vw;
    width: 85vw;
    margin-top: 4vh;
    margin-bottom: 10vh; }

.whoweare {
    margin-left: 3vw; }

.whatwedo {
    margin-left: 3vw; }

.team-position {
    margin-top: 0.3vh;
    text-transform: uppercase;
    letter-spacing: 0.1vw;
    margin-top: 0.7vh; }

@media only screen and(min-width: 835px) {
    .content-about {
        width: 45vw;
        font-size: 1.3vw;
        margin-bottom: 4vw; }

    .about-titles {
        font-size: 1.2vw; }

    .team-description {
        font-size: 1.1vw;
        line-height: 1.8vw; }

    .team-position {
        font-size: 1vw; }

    .whoweare {
        margin-left: 1vw; }

    .whatwedo {
        margin-left: 1vw;
        line-height: 1.9vw; } }


