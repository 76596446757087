.info-grid ul {
    list-style-type: none; }

.info-grid {
    font-size: 3.5vw;
    display: flex;
    justify-content: space-between;
    padding: 5vw 5vw;
    border-top: 1px solid black;
    min-height: 10vh; }

.feature-title {
    font-size: 5.5vw;
    max-width: 35vw; }

.feature-info {
    max-width: 50vw; }


.feature-info p {
    display: inline-block;
    margin: 0; }

.intro-img {
    max-width: 94vw;
    height: 60vh;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    position: absolute; }

.intro-img.active {
    z-index: 3; }

.info-grid {
    z-index: 1;
    position: absolute;
    background-color: $background; }

.info-grid.active {
    z-index: 3; }

.fit-left {
    object-fit: left; }

.hamburger:focus {
    outline: none; }

@media screen and(max-width: 835px) {
    .info-grid {
        width: 90vw; } }

@media screen and(min-width: 835px) {
    .intro-img {
        height: 97vh;
        margin: 1.5vh 1.5vh 1.5vh 0;
        width: 100%;
        object-fit: cover;
        object-position: center; }

    .feature-info {
        font-size: 1vw;
        line-height: 1.5vw;
        margin-left: 8vw; }

    .feature-title {
        font-size: 1.4vw;
        line-height: 2vw; }

    .info-grid {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border: none;
        text-align: right;
        margin-right: 3.5vw;
        width: 23%;
        margin-bottom: 1.5vh;
        padding: 0;
        height: 20vh; }

    h1 {
        font-size: 2.2vw; } }
