.mobile-grid {
    z-index: 3;
    width: 100%;
    border-bottom: 1px solid black;
    background-color: $background;
    padding: 5vw 0 0 5vw; }


.mobile-sidebar {
    padding: 7vw 0 0 10vw; }


.menu ul {
    width: 100%;
    font-size: 5vw;
    line-height: 9vw;
    list-style-type: none;
    padding: 0;
    margin: 0; }

.menu {
    display: none;
    width: 80%;
    height: 100vh; }

.projects {
    margin-left: 5vw;
    display: none; }

.projects-open {
    display: block; }

.logo-section {
    width: 100%; }

.logo {
    width: 12vw;
    margin-bottom: 4vw; }

.logo-open {
    width: 16vw !important;
    margin-bottom: 5vw; }

.content-area {
    height: 60vh;
    margin: 3vw; }

.hamburger {
    position: fixed;
    right: 5vw;
    margin-top: 3.5vw !important; }

.hidden-content {
    display: none; }

@media only screen and(min-width: 835px) {
    .mobile-grid {
        background-color: transparent;
        border: none;
        padding: 0; }

    .hamburger {
        display: none !important; }


    .desktop {
        display: block; }

    .mobile-grid {
        display: grid;
        grid-template-columns: 9% 17% 74%;
        width: 100vw;
        overflow: hidden; }

    .content-area {
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        width: 74%;
        margin-left: 26%;
        border-left: 1px solid black;
        height: 100vh;
        margin-right: 1.5vh; }

    .logo {
        width: 5vw; }

    .logo-section {
        text-align: center;
        margin-top: 4vh; }

    .menu ul {
        margin-top: 0;
        list-style-type: none;
        padding-left: 0;
        font-size: 1.4vw;
        line-height: 2vw; }

    .menu :hover {
        color: $pink; }

    .menu {
        display: block;
        margin-top: 4vh;
        margin-left: 1vw; }

    .projects {
        margin-left: 1.5vw;
        max-width: 10vw;
        display: none; }

    .projects-open {
        display: block !important; } }
