.project-flex {
    display: flex;
    flex-direction: column; }

.project-details {
    margin-top: 4vh; }

.project-details p {
    margin: 0;
    display: inline; }

.content-scroll {
    overflow: scroll; }


.project-images {
    margin-top: 2vh; }

.project-images img {
    width: 86vw;
    margin-bottom: 1vh; }

.content-project {
    margin-left: 3vw;
    width: 86vw;
    margin-top: 4vh;
    margin-bottom: 10vh; }



@media only screen and (max-width: 835px) {
    .content-scroll {
        height: 80vh; }

    .project-synopsis {
        margin-top: 2vh; } }

@media only screen and (min-width: 835px) {
    .project-images img {
        width: 69vw; }

    .project-flex {
        flex-direction: row;
        margin-top: 0.2vw;
        line-height: 1.6vw; }

    .project-synopsis {
        margin-left: 3vw;
        width: 52vw; }

    .content-project {
        width: 60vw;
        margin-bottom: 4vw; }

    .project-details {
        width: 50vw;
        margin-top: 1em; } }
